import React, { useMemo, useCallback } from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import DownloadDoneIcon from '@mui/icons-material/DownloadDone';
import { PartnerResource as PartnerResourceInterface, PartnerResourceMetadata, ResourceImageType } from '../../interfaces/partnerResources.interface';
import { postDownloadPartnerResource } from '../../services/partnerResources';
import { useAppSelector } from '../../app/hooks';
import { useTheme } from '@mui/material/styles';

export default function PartnerResource({
  partnerResource,
  handleBackClick,
  handleUpdateResource,
  uniqueDownloadCount,
}: {
  partnerResource: PartnerResourceInterface;
  handleUpdateResource: (resource: PartnerResourceInterface) => void;
  handleBackClick: () => void;
  uniqueDownloadCount: (metaData: PartnerResourceMetadata[]) => number;
}) {
  const { user } = useAppSelector((state) => state.user);
  const theme = useTheme();
  const resourceImage = useMemo(() => partnerResource.images.find((image) => image.type === ResourceImageType.IMAGE) || partnerResource.images[0], [partnerResource.images]);
  const resourceDoc = useMemo(() => partnerResource.images.find((image) => image.type === ResourceImageType.DOCUMENT), [partnerResource.images]);
  const handleDownloadPartnerResource = useCallback(async () => {
    if (!partnerResource.id || !user || !resourceDoc) return;
    try {
      // Post metadata update
      const metaData = await postDownloadPartnerResource({
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        downloadedByUser: user.id,
        resource: partnerResource.id as number,
      });

      if (!metaData) return;

      handleUpdateResource({
        ...partnerResource,
        metadata: [...partnerResource.metadata, metaData],
      });

      // Trigger the download
      const link = document.createElement('a');
      link.href = resourceDoc.image; // Use the S3 link
      link.download = partnerResource.title;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error(error);
    }
  }, [partnerResource, user, resourceDoc, handleUpdateResource]);

  const hasDownloaded = useMemo(() => user && partnerResource.metadata.some((meta) => meta.downloadedByUser === user.id), [partnerResource.metadata, user]);

  return (
        <Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button variant="text" sx={{ px: '30px' }} startIcon={<ArrowBackIcon />} onClick={handleBackClick}>
                Back
            </Button>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', my: 2, gap: 20 }}>
            <Box>
                <Typography
                    variant="h4"
                    sx={{
                      fontWeight: 'bold',
                      mb: 2,
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                    }}
                >
                    {partnerResource.title}
                    <Box
                        component="span"
                        sx={{
                          display: 'inline-block',
                          width: '2px',
                          height: '30px',
                          backgroundColor: theme.palette.customThemeColors?.darkGrey,
                          marginX: 1,
                        }}
                    />
                    <Box
                        component="span"
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          color: '#555',
                          fontSize: '1rem',
                          fontWeight: '500',
                        }}
                    >
                        <DownloadIcon sx={{ fontSize: '1.2rem', mr: 0.5 }} />
                        {uniqueDownloadCount(partnerResource.metadata)} users
                    </Box>
                </Typography>
                <Typography
                    variant="body1"
                    sx={{
                      fontSize: '1rem',
                      lineHeight: 1.5,
                      textAlign: 'justify',
                    }}
                >
                    {partnerResource.description}
                </Typography>
            </Box>
            <Box>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={!resourceDoc?.image}
                    onClick={handleDownloadPartnerResource}
                    startIcon={ hasDownloaded ? <DownloadDoneIcon /> : <DownloadIcon /> }
                >
                    Download
                </Button>
            </Box>
        </Box>
        <Box
            sx={{
              mb: 4,
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
        >
            <Box
                component="img"
                sx={{
                  maxHeight: '500px',
                  maxWidth: '100%',
                  objectFit: 'contain',
                  border: '1px solid #e0e0e0',
                  borderRadius: '8px',
                  boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                }}
                src={resourceImage?.image}
                alt="Document preview"
            />
        </Box>
    </Box>
  );
}