export enum ResourceImageType {
  IMAGE = 'resource_image',
  DOCUMENT = 'resource_document',
}

export interface PartnerResource {
  id?: number;
  title: string;
  subDescription: string;
  description: string;
  createdAt?: string;
  updatedAt?: string;
  metadata: PartnerResourceMetadata[];
  images: PartnerResourceImage[];
  category: PartnerResourceCategory;
  version?: string;
}

export interface PartnerResourceImage {
  id?: number;
  createdAt: string;
  image: string;
  resource: number;
  type: ResourceImageType;
  updatedAt: string;
}

export interface PartnerResourceMetadata {
  id?: number;
  createdAt?: string;
  downloadedByUser: number;
  resource: number;
  updatedAt?: string;
}

export interface PartnerResourceCategory {
  id: number;
  description: string;
  title: string;
}
